<template>

</template>

<script>
export default {
  name: 'transactionsList'
}
</script>

<style scoped>

</style>
